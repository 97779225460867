export const SORT_DAY = {
  Lastest: 1,
  Oldest: 0,
};

export const KIND_OF = {
  Reputation: 1,
  Cheat: 2,
};

export const TYPE = {
  Website: 1,
  BankAccount: 2,
  SocialAccount: 3,
  NumberPhone: 4,
};
