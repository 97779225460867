const types = [
  {
    value: 0,
    label: "Tất cả",
  },
  {
    value: 1,
    label: "Website",
  },
  {
    value: 2,
    label: "Số tài khoản",
  },
  {
    value: 3,
    label: "Tài khoản MXH",
  },
  {
    value: 4,
    label: "Số điện thoại",
  },
];
export default types;
